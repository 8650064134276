import React from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Collapsible,
  Columns,
  Divider,
  EmptyState,
  FilterIcon,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  Inline,
  Input,
  Link,
  Main,
  Paragraph,
  Row,
  SearchIcon,
  Select,
  Spacer,
  Wrapper
} from '@te-digi/styleguide'

import { HeaderExample } from '../../examples/react/HeaderExamples'
import { FooterExample } from '../../examples/react/FooterExample'

type ExampleProps = {
  className?: string
}

const PatternExampleHakulomake4 = ({ className }: ExampleProps) => (
  <Wrapper className={className}>
    <HeaderExample />
    <Columns layout="wide">
      <Main>
        <Breadcrumb ariaLabel="Murupolku">
          <BreadcrumbItem>
            <Link>Etusivu</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Sisältösivu</BreadcrumbItem>
        </Breadcrumb>
        <Heading
          level={1}
          size={1}
        >
          Sisältösivu
        </Heading>
        <Paragraph>Sivun muu sisältö.</Paragraph>
        <Heading
          level={2}
          size={3}
        >
          {'<Hakuosion otsikko>'}
        </Heading>
        <Paragraph>
          Tarkempia ohjeita hakuun voidaan tarjota otsikon alla tavallisena
          tekstinä.
        </Paragraph>
        <FormLayout>
          <FormLayoutItem>
            <FormGroup>
              <Row
                alignItems="end"
                justifyContent="between"
                gap="md"
              >
                <Col>
                  <Input
                    clearable
                    label="Hakusana"
                    subLabel="Hae <tietoja> esimerkiksi <hakusanatyypillä> tai <hakusanatyypillä>"
                  />
                </Col>
                <Col
                  lg="auto"
                  xs={12}
                >
                  <Spacer marginTop="sm">
                    <Button iconLeft={<SearchIcon />}>Hae {'<tietoja>'}</Button>
                  </Spacer>
                </Col>
              </Row>
            </FormGroup>
            <Row>
              <Col
                lg={6}
                xs={12}
              >
                <FormGroup>
                  <Select
                    label="Tärkeä hakuehto"
                    placeholder="Kirjoita tai valitse..."
                  />
                </FormGroup>
              </Col>
              <Col
                lg={6}
                xs={12}
              >
                <FormGroup>
                  <Select
                    label="Tärkeä hakuehto"
                    placeholder="Kirjoita tai valitse..."
                  />
                </FormGroup>
              </Col>
            </Row>
            <Divider />
            <Collapsible
              inline
              color="light"
              isOpen={false}
              label={
                <Inline
                  alignItems="start"
                  gap="xs"
                  justifyContent="start"
                >
                  <FilterIcon
                    color="primary"
                    size="lg"
                  />
                  <Paragraph noMargin>Näytä tarkemmat hakuehdot</Paragraph>
                </Inline>
              }
            />
          </FormLayoutItem>
        </FormLayout>
        <Spacer marginTop="layout-md">
          <EmptyState
            content="Muokkaa hakuehtoja ja tee uusi haku."
            icon={<SearchIcon />}
            title="Haku ei tuottanut tuloksia."
          />
        </Spacer>
      </Main>
    </Columns>
    <FooterExample />
  </Wrapper>
)

export { PatternExampleHakulomake4 }
