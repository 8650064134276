import React from 'react'

import {
  Button,
  Col,
  Collapsible,
  Columns,
  Divider,
  EmptyState,
  FilterIcon,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Inline,
  Input,
  Main,
  Paragraph,
  Row,
  SearchIcon,
  Select,
  Spacer
} from '@te-digi/styleguide'

const PatternExampleHakulomake2 = () => (
  <Columns layout="wide">
    <Main>
      <FormLayout>
        <FormLayoutItem>
          <FormGroup>
            <Row
              alignItems="end"
              justifyContent="between"
              gap="md"
            >
              <Col>
                <Input
                  clearable
                  label="Hakusana"
                  subLabel="Hae <tietoja> esimerkiksi <hakusanatyypillä> tai <hakusanatyypillä>"
                />
              </Col>
              <Col
                lg="auto"
                xs={12}
              >
                <Spacer marginTop="sm">
                  <Button iconLeft={<SearchIcon />}>Hae {'<tietoja>'}</Button>
                </Spacer>
              </Col>
            </Row>
          </FormGroup>
          <Row>
            <Col
              lg={6}
              xs={12}
            >
              <FormGroup>
                <Select
                  label="Tärkeä hakuehto"
                  placeholder="Kirjoita tai valitse..."
                />
              </FormGroup>
            </Col>
            <Col
              lg={6}
              xs={12}
            >
              <FormGroup>
                <Select
                  label="Tärkeä hakuehto"
                  placeholder="Kirjoita tai valitse..."
                />
              </FormGroup>
            </Col>
          </Row>
          <Divider />
          <Collapsible
            inline
            color="light"
            isOpen={false}
            label={
              <Inline
                alignItems="start"
                gap="xs"
                justifyContent="start"
              >
                <FilterIcon
                  color="primary"
                  size="lg"
                />
                <Paragraph noMargin>Näytä tarkemmat hakuehdot</Paragraph>
              </Inline>
            }
          />
        </FormLayoutItem>
      </FormLayout>
      <Spacer marginTop="layout-md">
        <EmptyState
          content="Muokkaa hakuehtoja ja tee uusi haku."
          icon={<SearchIcon />}
          title="Haku ei tuottanut tuloksia."
        />
      </Spacer>
    </Main>
  </Columns>
)

export { PatternExampleHakulomake2 }
