import React, { useState } from 'react'

import {
  Button,
  ButtonGroup,
  CardViewFillIcon,
  Checkbox,
  CheckboxGroup,
  Col,
  Collapsible,
  Columns,
  Divider,
  FilterIcon,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  Inline,
  InlineLabel,
  InlineList,
  InlineListItem,
  Input,
  ListViewFillIcon,
  Main,
  Paragraph,
  Radio,
  RadioGroup,
  Row,
  SearchIcon,
  Select,
  Spacer,
  SquareButton,
  Stack,
  Tag,
  TagButton,
  TagDivider,
  Tags,
  XIcon
} from '@te-digi/styleguide'

const PatternExampleHakulomake1 = () => {
  const [isOpen1, setIsOpen1] = useState(true)

  return (
    <Columns layout="wide">
      <Main>
        <FormLayout>
          <FormLayoutItem>
            <div data-note="1">
              <FormGroup>
                <Row
                  alignItems="end"
                  justifyContent="between"
                  gap="md"
                >
                  <Col>
                    <Input
                      clearable
                      label="Hakusana"
                      subLabel="Hae <tietoja> esimerkiksi <hakusanatyypillä> tai <hakusanatyypillä>"
                    />
                  </Col>
                  <Col
                    lg="auto"
                    xs={12}
                  >
                    <Spacer marginTop="sm">
                      <Button iconLeft={<SearchIcon />}>
                        Hae {'<tietoja>'}
                      </Button>
                    </Spacer>
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <div data-note="2">
              <Row>
                <Col
                  lg={6}
                  xs={12}
                >
                  <FormGroup>
                    <Select
                      label="Tärkeä hakuehto"
                      placeholder="Kirjoita tai valitse..."
                    />
                  </FormGroup>
                </Col>
                <Col
                  lg={6}
                  xs={12}
                >
                  <FormGroup>
                    <Select
                      label="Tärkeä hakuehto"
                      placeholder="Kirjoita tai valitse..."
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <Divider />
            <div data-note="3">
              <Collapsible
                inline
                color="light"
                isOpen={isOpen1}
                label={
                  <Inline
                    alignItems="start"
                    gap="xs"
                    justifyContent="start"
                  >
                    <FilterIcon
                      color="primary"
                      size="lg"
                    />
                    <Paragraph noMargin>Piilota tarkemmat hakuehdot</Paragraph>
                  </Inline>
                }
                onToggle={() => setIsOpen1(!isOpen1)}
              >
                <Row>
                  <Col
                    lg={6}
                    xs={12}
                  >
                    <Stack gap="xs">
                      <FormGroup noMargin>
                        <Select
                          label="Tarkempi hakuehto"
                          placeholder="Kirjoita tai valitse..."
                        />
                      </FormGroup>
                      <Spacer marginBottom="md">
                        <Tag
                          color="primary"
                          label="Valittu hakuehto"
                          size="xs"
                        >
                          <TagDivider />
                          <TagButton icon={<XIcon />}>Poista</TagButton>
                        </Tag>
                      </Spacer>
                    </Stack>
                  </Col>
                  <Col
                    lg={6}
                    xs={12}
                  >
                    <FormGroup>
                      <Select
                        label="Tarkempi hakuehto"
                        placeholder="Kirjoita tai valitse..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={4}
                    xs={12}
                  >
                    <FormGroup>
                      <Select
                        label="Tarkempi hakuehto"
                        placeholder="Kirjoita tai valitse..."
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    lg={4}
                    xs={12}
                  >
                    <FormGroup>
                      <Select
                        label="Tarkempi hakuehto"
                        placeholder="Kirjoita tai valitse..."
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    lg={4}
                    xs={12}
                  >
                    <FormGroup>
                      <Select
                        label="Tarkempi hakuehto"
                        placeholder="Kirjoita tai valitse..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={4}
                    xs={12}
                  >
                    <FormGroup>
                      <RadioGroup
                        label="Hakuehto A"
                        value="1"
                        onChange={() => {}}
                      >
                        <Radio value="1">Vaihtoehto 1</Radio>
                        <Radio value="2">Vaihtoehto 2</Radio>
                        <Radio value="3">Vaihtoehto 3</Radio>
                      </RadioGroup>
                    </FormGroup>
                  </Col>
                  <Col
                    lg={4}
                    xs={12}
                  >
                    <FormGroup>
                      <RadioGroup
                        label="Hakuehto B"
                        value="2"
                        onChange={() => {}}
                      >
                        <Radio value="1">Vaihtoehto 1</Radio>
                        <Radio value="2">Vaihtoehto 2</Radio>
                        <Radio value="3">Vaihtoehto 3</Radio>
                      </RadioGroup>
                    </FormGroup>
                  </Col>
                  <Col
                    lg={4}
                    xs={12}
                  >
                    <FormGroup>
                      <CheckboxGroup
                        label="Hakuehto"
                        onChange={() => {}}
                        value={['3']}
                      >
                        <Checkbox value="1">Vaihtoehto 1</Checkbox>
                        <Checkbox value="2">Vaihtoehto 2</Checkbox>
                        <Checkbox value="3">Vaihtoehto 3</Checkbox>
                      </CheckboxGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Collapsible>
            </div>
          </FormLayoutItem>
        </FormLayout>
        <div data-note="4">
          <Spacer marginBottom="md">
            <Heading
              level={4}
              noMargin
              size={4}
            >
              Nykyinen hakusi
            </Heading>
          </Spacer>
          <Row
            alignItems="center"
            gap="xs"
            justifyContent="start"
          >
            <Col xs="auto">
              <Tags noMargin>
                <Tag
                  label="hakusana"
                  size="xs"
                >
                  <TagDivider />
                  <TagButton icon={<XIcon />}>Poista</TagButton>
                </Tag>
                <Tag
                  color="primary"
                  label="Valittu hakuehto"
                  size="xs"
                >
                  <TagDivider />
                  <TagButton icon={<XIcon />}>Poista</TagButton>
                </Tag>
              </Tags>
            </Col>
            <Col xs="auto">
              <Button
                iconLeft={<XIcon />}
                variant="plain"
              >
                Poista kaikki hakuehdot
              </Button>
            </Col>
          </Row>
        </div>
        <Divider />
        <div data-note="5">
          <Row>
            <Col>
              <InlineList>
                <InlineListItem>Löytyi N osumaa</InlineListItem>
                <InlineListItem>Sivu 1/N</InlineListItem>
              </InlineList>
            </Col>
            <Col xl="auto">
              <InlineLabel
                htmlFor="result-example-order"
                label="Järjestys"
              >
                <Select
                  id="result-example-order"
                  placeholder="Osuvin"
                />
              </InlineLabel>
            </Col>
            <Col xl="auto">
              <InlineLabel
                htmlFor="result-example-order"
                label="Järjestys"
              >
                <ButtonGroup>
                  <SquareButton iconLeft={<CardViewFillIcon />}>
                    Kortit
                  </SquareButton>
                  <SquareButton
                    iconLeft={<ListViewFillIcon />}
                    variant="outline"
                  >
                    Lista
                  </SquareButton>
                </ButtonGroup>
              </InlineLabel>
            </Col>
          </Row>
        </div>
      </Main>
    </Columns>
  )
}

export { PatternExampleHakulomake1 }
