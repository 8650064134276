import React from 'react'
import styled from 'styled-components'

import {
  Button,
  Col,
  FormGroup,
  FormLayoutItem,
  Help,
  Inline,
  Input,
  List,
  ListItem,
  Message,
  Paragraph,
  Row,
  SearchIcon,
  Spacer,
  Strong,
  ThumbDownIcon,
  ThumbUpIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { FormPageExample } from '../../examples/react/pages/FormPageExample'
import { Link } from '../../components/Link'
import { NoteItem } from '../../components/NoteItem'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { PatternExampleHakulomake1 } from '../../examples/react/PatternExampleHakulomake1'
import { PatternExampleHakulomake2 } from '../../examples/react/PatternExampleHakulomake2'
import { PatternExampleHakulomake3 } from '../../examples/react/PatternExampleHakulomake3'
import { PatternExampleHakulomake4 } from '../../examples/react/PatternExampleHakulomake4'
import { ZoomedExample } from '../../components/ZoomedExample'
import { Playground } from '../../components/Playground'
import { PatternExampleHakulomakeActive } from '../../examples/react/PatternExampleHakulomakeActive'
import { PatternExampleSearchInModal } from '../../examples/react/PatternExampleSearchInModal'

export const StyledFormPageExample = styled(FormPageExample)`
  [data-note='4'] {
    position: relative;
    transform: none;
  }
`

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Hakulomake">
    <Section title="Periaatteet">
      <Paragraph>
        Hakulomakkeella on kahdenlaisia tietoa rajaavia toiminnallisuuksia:
      </Paragraph>
      <SectionHeading>Hakusana</SectionHeading>
      <Paragraph noMargin>
        Hakusana on tekstikenttä (
        <Code>
          <Link page="Input" />
        </Code>
        ), johon käyttäjä voi kirjoittaa vapaamuotoisen hakukriteerin.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Strong>
            Hakusanan kirjoittaminen kenttään ei suorita hakua automaattisesti
          </Strong>
          , vaan käyttäjän on suoritettava haku kentän vieressä näytettävällä
          Hae-painikkeella tai painamalla enter.
        </ListItem>
        <ListItem>
          Tekstimuotoisen sisällön lisäksi hakusana voidaan ulottaa koskemaan
          myös rakenteellisia tietoja. Tällöin samaa tietoa ei suositella
          käytetäväksi erillisenä hakuehtona.
        </ListItem>
      </List>
      <Spacer marginBottom="layout-md">
        <Help>
          Hakusanakenttä on suunnitteilla eriyttää omaksi komponentikseen
          (SearchInput), johon mahdollista mm. lisätä hakuehdotuksia
        </Help>
        <Playground>
          <FormLayoutItem>
            <FormGroup>
              <Row
                alignItems="end"
                justifyContent="between"
                gap="md"
              >
                <Col>
                  <Input
                    clearable
                    label="Hakusana"
                    subLabel="Hae <tietoja> esimerkiksi <hakusanatyypillä> tai <hakusanatyypillä>"
                  />
                </Col>
                <Col
                  lg="auto"
                  xs={12}
                >
                  <Spacer marginTop="sm">
                    <Button iconLeft={<SearchIcon />}>Hae {'<tietoja>'}</Button>
                  </Spacer>
                </Col>
              </Row>
            </FormGroup>
          </FormLayoutItem>
        </Playground>
      </Spacer>
      <SectionHeading>Hakuehdot</SectionHeading>
      <Paragraph noMargin>
        Lomakekenttiä, joilla käyttäjä voi valita hakua rajaavia rakenteisia
        tietoja.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Strong>Hakuehdot päivittyvät hakuun automaattisesti</Strong>, heti
          kun käyttäjä on valinnut hakuehdon (toisin kuin hakusana, joka vaatii
          käyttäjän hyväksynnän)
        </ListItem>
        <ListItem>
          Hakuehdot listataan tärkeysjärjestyksessä ja ryhmitellään niin, että
          toisiinsa liittyvät hakuehdot ovat peräkkäin.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Desktop-näkymässä samalle riville voidaan ryhmitellä rinnakkain
              enintään 2–3 select-valintaa tai 4 valintapainikeryhmää
              (vaihtoehtojen tekstin pituudesta riippuen).
            </ListItem>
            <ListItem>
              Mobiilinäkymässä hakuehdot ryhmitellään allekkain.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          Hakuehdot pyritään nimeämään mahdollisimman selkeästi ja lyhyesti.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Käyttäjän tulisi ymmärtää pelkän Label:n perusteella miten
              hakuehto vaikuttaa hakuun.
            </ListItem>
            <ListItem>Sublabelia käytetään vain erityistapauksissa</ListItem>
            <ListItem>
              Kts. tarkempi ohjeistus <Link page="Lomakekentän otsikointi" />
              -patternista
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          Jos hakulomake sisältää suodattavia hakuehtoja, ei hakutulosten
          listauksessa tarjota erikseen muita suodatustapoja.
        </ListItem>
      </List>
      <Message attentionColor="success">
        <Inline gapX="xs">
          <ThumbUpIcon
            color="success"
            size="lg"
          />
          <Strong>Hakuehtojen valintaan käytetään ensisijaisesti:</Strong>
        </Inline>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <Code>
              <Link page="Select" />
            </Code>
            -komponenttia, kun hakuehdossa on useita vaihtoehtoja (yli 4)
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Hakuehdoista voi valita joko yhden tai useita.
              </ListItem>
              <ListItem>
                Valitut hakuehdot listataan Select-komponentin alla{' '}
                <Code>
                  <Link page="Tag" />
                </Code>
                -komponenteilla (<Code>color='primary' size='xs'</Code>)
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <Code>
              <Link page="RadioGroup" />
            </Code>
            -komponenttia, kun käyttäjä valitsee yhden vaihtoehdon toisensa
            poissulkevista hakuehdoista, joita on alle 4.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Tällöin tarjotaan yleensä myös vaihtoehto <em>Kaikki</em>.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <Code>
              <Link page="Checkbox" />
            </Code>
            -komponentteja, kun käyttäjä valitsee yksittäisen hakuehdon tai
            toisiaan täydentäviä hakuehtoja, joita on alle neljä.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Toisiaan täydentävät vaihtoehdot ryhmitellään yhteisen labelin
                alle, yksittäinen boolean-valinta voidaan näyttää ilman
                erillistä labelia.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <Code>
              <Link page="Filter" />
            </Code>
            -komponenttia, kun halutaan korostaa tärkeää hakuehtoa, jolla on
            alle 4 vaihtoehtoa.
          </ListItem>
          <ListItem>
            Valitse-painikkeesta avautuvaa dialogia (
            <Code>
              <Link page="Modal" />
            </Code>
            ), kun hakuehdon valinta on liian monimutkainen mahtumaan
            hakulomakkeelle.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Valitut hakuehdot näytetään käyttäjälle modaalissa ja Nykyinen
                hakusi -alueella, mutta yksittäisiä hakuehtoja ei lisätä
                dialogin avaavan painikkeen yhteyteen, kuten Selectin
                yhteydessä.
              </ListItem>
            </List>
          </ListItem>
        </List>
      </Message>
      <Message attentionColor="danger">
        <Inline gapX="xs">
          <ThumbDownIcon
            color="danger"
            size="lg"
          />
          <Strong>Hakuehtojen valinnassa vältetään käyttämästä:</Strong>
        </Inline>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <Code>
              <Link page="Input" />
            </Code>
            -komponenttia, koska tekstinä syötetyn hakuehdon automaattinen
            päivittyminen voi olla käyttäjälle vaikeasti hahmotettava.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Tekstimuotoiset hakuehdot pyritään aina ensisijaisesti
                toteuttamaan hakusanakentän avulla.
              </ListItem>
              <ListItem>
                Inputia voidaan kuitenkin käyttää erikoistapauksissa, jos
                hakuehtoa on mahdoton toteuttaa valintana ja sitä ei voida
                liittää osaksi hakusanalla haettavia tietoja.
              </ListItem>
              <ListItem>
                Käytettäessä Input-kenttää hakuehtona on sen toiminnallisuudessa
                huomioitava:
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>
                    kentällä voidaan syöttää vain yksi hakuehto kerralla,
                  </ListItem>
                  <ListItem>
                    kenttään syötetty teksti päivitetään hakuehdoksi
                    automaattisesti,
                  </ListItem>
                  <ListItem>
                    pyynnnön lähettämiseen asetetaan pieni viive, jotta hakuehto
                    ei päivity välittömästi jokaisen näppäimen lyönnin jälkeen,
                    vaan vasta kun käyttäjä on hetken kirjoittamatta,
                  </ListItem>
                  <ListItem>
                    syötetty hakuehto luetaan ruudunlukijalla (
                    <Code>
                      hidden <Link page="Notifications" />
                    </Code>
                    , &quot;Hakuehto x lisätty&quot;) ja lisätään &quot;Nykyinen
                    hakusi&quot;-alueelle kun hakua päivitetään.
                  </ListItem>
                </List>
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <Code>
              <Link page="Switch" />
            </Code>
            -painikkeita.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Valinta toteutetaan ensisijaisesti{' '}
                <Code>
                  <Link page="Checkbox" />
                </Code>
                -komponentilla.
              </ListItem>
              <ListItem>
                Switch-painiketta voidaan käyttää erikoistapauksissa, jos
                hakuehto on lyhyt ja ytimekäs päällä/pois-tyyppinen valinta,
                jota halutaan korostaa visuaalisesti.
              </ListItem>
            </List>
          </ListItem>
        </List>
      </Message>
    </Section>
    <Section title="Saavutettavuus">
      <List variant="unordered">
        <ListItem>
          Hakuehdoille määritellään <Code>autocomplete="off"</Code>.
        </ListItem>
        <ListItem>
          Hakuehdon lisäämisestä tai poistamisesta tiedotetaan ruudunlukijalle (
          <Code>
            hidden <Link page="Notifications" />
          </Code>
          ).
        </ListItem>
        <ListItem>
          Hakutulosten määrä päivittyy dynaamisesti, kun hakuehtoja muokataan.
          Tieto tulee siis ilmoittaa elementissä, jolle on annettu{' '}
          <Code>aria-live="polite"</Code> -määritys, jotta ruudunlukija osaa
          lukea muuttuneen tiedon.
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <Help>
        <Paragraph noMargin>
          Esimerkin responsiviinen asettelu toimii parhaiten koko näytön koossa
          tarkasteltuna. Näkymä avautuu esimerkkialueen alanurkasta
          Suurenna-painikkeesta.
        </Paragraph>
      </Help>
      <Playground
        enableOverflow
        example={PatternExampleHakulomakeActive}
      />
      <SectionHeading>Haun suorittaminen</SectionHeading>
      <List variant="unordered">
        <ListItem>
          <Strong>Haun logiikka</Strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Kustakin hakuehdosta käyttäjä voi valita tapauskohtaisesti joko
              yhden tai useita hakuehtoja, joiden yhteisvaikutus rajaa
              listattavia tuloksia.
            </ListItem>
            <ListItem>
              Eri kentistä valittavat hakuehdot suodattavat hakua keskenään
              AND-logiikalla (listauksessa näytetään tulokset joissa löytyy sekä
              A että B)
            </ListItem>
            <ListItem>
              Saman hakuehdon sisällä valitut ehdot suodattavat hakua
              OR-logiikalla (listataan tulokset joissa on joko A tai B)
            </ListItem>
            <ListItem>
              Hakuehdolle voidaan myös syöttää sivulle navigoitaessa esivalinta
              tai sillä voi olla suoraan hakua suodattava oletusarvo
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Strong>Valitut hakuehdot liitetään sivun URL:iin</Strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>Haku suoritetaan päivitetyn URL:n parametreillä</ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Strong>
            Käynnissä olevan haun aikana käyttäjälle viestitään järjestelmän
            tilasta
          </Strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Hakusana-kentän Hae-painike asetetaan loading-tilaan (
              <Code>loading='true'</Code>)
            </ListItem>
            <ListItem>
              &quot;Hakutulokset&quot;-alueen (5) päällä näytetään{' '}
              <Code>
                <Link page="LoadingOverlay" />
              </Code>
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Strong>Haun valmistuttua</Strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Valitut hakuehdot ja syötetty hakusana listataan{' '}
              <Code>
                <Link page="Tag" />
              </Code>
              -komponentteina (<Code>size='xs'</Code>) &quot;Nykyinen
              hakusi&quot; -alueella (4).
            </ListItem>
            <ListItem>
              Löydettyjen osumien määrä luetaan ruudunlukijalla:{' '}
              <em>&quot;Löytyi N osumaa&quot;</em> (tämä päivittyvä tieto
              elementissä, jolle on annettu <Code>aria-live="polite"</Code>{' '}
              -määritys)
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Strong>Käyttäjä voi poistaa hakusanan tai hakuehtoja:</Strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              kaikki hakuehdot &quot;Nykyinen hakusi&quot; -alueen (4) Tyhjennä
              haku -painikkeesta,
            </ListItem>
            <ListItem>yksittäisen hakuehdon Tagin poistopainikkeesta.</ListItem>
            <ListItem>
              <Strong>
                Hakuehdon poistamisesta ilmoitetaan ruudunlukijalle
              </Strong>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <SectionHeading>Perusosat</SectionHeading>
      <NoteItem
        bullet="1"
        title="Hakusana-kenttä"
      >
        <Paragraph noMargin>
          <Strong>Hakusana erotetaan lomakkeella omaksi rivikseen</Strong>,
          sillä muista hakuehdoista poiketen se ei päivitä hakua automaattisesti
        </Paragraph>
        <List variant="unordered">
          <ListItem>
            Hakusanakenttä on tyhjennettävä (<Code>clearable</Code>)
          </ListItem>
          <ListItem>
            Kentän vieressä näytetään haun suorittava Hae-painike (
            <Code>
              <Link page="Button" />
            </Code>
            , jossa vasemmalla <Code>SearchIcon</Code>)
          </ListItem>
          <ListItem>
            Painikkeessa on hyvä myös kertoa mitä tietoja painikkeesta
            painettaessa haetaan (esim. &quot;Hae palveluita&quot;)
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="2"
        title="Tärkeimmät hakuehdot -alue"
      >
        <List variant="unordered">
          <ListItem>
            Kun hakuehtoja on vähän (alle kuusi kappaletta), ne voidaan ne
            kaikki näyttää aina hakukentän alla.
          </ListItem>
          <ListItem>
            Kun hakuehtoja on paljon, niistä vain tärkeimmät näytetään suoraan
            hakukentän alla ja vähemmän tärkeät ehdot piilotetaan painikkeesta
            avautuvalle Tarkemmat hakuehdot -alueelle (3)
          </ListItem>
          <ListItem>
            Alueella näytettävät hakuehdot valitaan tarkkaan ja niihin nostetaan
            mieluiten enintään kolme tärkeintä hakuehtoa, ehdottomasti enintään
            kuusi.
          </ListItem>
        </List>
      </NoteItem>
      <SectionHeading>Lisäosat</SectionHeading>
      <NoteItem
        bullet={3}
        title="Tarkemmat hakuehdot -alue"
      >
        <Paragraph noMargin>
          <Strong>
            Kun hakuehtoja on paljon, vähemmän oleelliset ehdot voidaan
            piilottaa avattavaan osioon
          </Strong>{' '}
          (
          <Code>
            <Link page="Collapsible" />
          </Code>
          , ks. tarkempi toteutus esimerkistä)
        </Paragraph>
        <List variant="unordered">
          <ListItem>
            Tarkemmat hakuehdot -alue näytetään oletuksena suljettuna.
          </ListItem>
          <ListItem>
            Collapsible-painike sijoitetaan heti tärkeimpien hakuehtojen perään,
            erotettuna{' '}
            <Code>
              <Link page="Divider" />
            </Code>
            -komponentilla
          </ListItem>
          <ListItem>
            Collapsible-painikkeen teksti vaihtuu tilan mukaan:
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Suljettuna: <em>&quot;Näytä tarkemmat hakuehdot&quot;</em>
              </ListItem>
              <ListItem>
                Avattuna: <em>&quot;Piilota tarkemmat hakuehdot&quot;</em>
              </ListItem>
            </List>
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="4"
        title="Nykyinen hakusi -alue"
      >
        <List variant="unordered">
          <ListItem>
            <Strong>
              Alueelle kootaan kaikki valitut hakuehdot{' '}
              <Code>
                <Link page="Tag" />
              </Code>
              :eina
            </Strong>
            , jotka käyttäjä voi poistaa yksitellen tai tyhjentää kaikki
            kerralla.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Hakusana näytetään järjestyksessä ensimmäisenä ja erotetaan
                muista hakuehdoista käyttämällä secondary-väriä (
                <Code>
                  <Link page="Tag" /> size='xs'
                </Code>
                )
              </ListItem>
              <ListItem>
                Hakuehdot listataan hakusanan jälkeen käyttäen primary-väriä (
                <Code>
                  <Link page="Tag" /> color='primary' size='xs'
                </Code>
                )
              </ListItem>
              <ListItem>
                Hakuehdot listataan samassa järjestyksessä, missä hakuehdot ovat
                lomakkeella.
              </ListItem>

              <ListItem>
                Tagien perässä näytetään &quot;Tyhjennä haku&quot;-painike, joka
                poistaa kaikki annetut hakuehdot (
                <Code>
                  <Link page="Button" /> variant='plain'
                </Code>
                , jossa oikealla <Code>XIcon</Code>)
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            Alue näytetään vasta kun vähintään yksi hakuehto on valittu.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Alue näytetään myös silloin, jos hakulomakkeelle on määritelty
                suoraan hakua suodattava esivalinta tai oletusarvo
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            Alue erotetaan lomakkeen alla näytettävästä hakulistauksesta{' '}
            <Code>
              <Link page="Divider" />
            </Code>
            -komponentilla
          </ListItem>
        </List>
      </NoteItem>
      <SectionHeading>Hakutulosten näyttäminen</SectionHeading>
      <NoteItem
        bullet="5"
        title="Hakutulokset -alue"
      >
        <Paragraph noMargin>
          Hakutulokset listataan heti hakulomakkeen jälkeen
        </Paragraph>
        <List variant="unordered">
          <ListItem>
            Jos haku ei tuota tuloksia, näytetään alueen tilalla{' '}
            <Code>
              <Link page="EmptyState" />
            </Code>
            .
          </ListItem>
          <ListItem>
            Käyttäjän saapuessa hakunäkymään, voidaan tilanteesta riippuen
            hakutuloksissa näyttää joko:
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>kaikki tietokannassa olevat tiedot</ListItem>
              <ListItem>
                valmiiksi esitäytetyillä hakuehdoilla rajattu haku (esivalitut
                ehdot annetaan sivun URL:ssa)
              </ListItem>
              <ListItem>
                tyhjä lähtötila (
                <Code>
                  <Link page="EmptyState" />
                </Code>
                ), johon listataan tuloksia vasta käyttäjän tehtyä haun
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>
                    tyhjää lähtötilaa voidaan käyttää erityisesti silloin, kun
                    haettava tieto on niin monimuotoista tai tietoja on niin
                    paljon, ettei käyttäjän ole hyödyllistä lähteä selaamaan
                    suodattamatonta listaa.
                  </ListItem>
                </List>
              </ListItem>
            </List>
          </ListItem>
        </List>
      </NoteItem>
      <ZoomedExample
        notes={[
          { id: '1' },
          { id: '2' },
          { id: '3' },
          { id: '4' },
          { id: '5' }
        ]}
      >
        <PatternExampleHakulomake1 />
      </ZoomedExample>
    </Section>
    <Section title="Tilat ja muunnelmat">
      <SectionHeading>Oletustila</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Tarkemmat hakuehdot-alue (3) näytetään oletuksena suljettuna
        </ListItem>
        <ListItem>
          Kun yhtään hakuehtoa ei ole valittu
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Piilotetaan &quot;Nykyinen hakusi&quot;-osio (4) ja sen
              hakutuloksista erottava <Code>Divider</Code>
            </ListItem>
            <ListItem>
              Hakutuloksissa voidaan tapauskohtaisesti joko
              <List
                noMargin
                variant="unordered"
              >
                <ListItem>
                  listata kaikki tietokannasta löytyvät tulokset ilman
                  rajauksia,
                </ListItem>
                <ListItem>
                  näyttää{' '}
                  <Code>
                    <Link page="EmptyState" />
                  </Code>
                  , joka kehottaa käyttäjää hakemaan tietoja hakulomakeella.
                </ListItem>
              </List>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Spacer marginBottom="layout-sm">
        <ZoomedExample>
          <PatternExampleHakulomake2 />
        </ZoomedExample>
      </Spacer>
      <SectionHeading>Hakulomake ilman hakusanakenttää</SectionHeading>
      <Paragraph noMargin>
        <Strong>
          Lähtökohtaisesti hakulomakkeilla on aina hakusana-kenttä (1).
        </Strong>{' '}
        Hakulomake-patternia voidaan kuitenkin tarvittaessa soveltaa myös muihin
        tietoa suodattaviin lomakkeisiin, joissa on tarjolla vain rakenteisia
        hakuehtoja. Näissä tapauksissa:
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          termien "hae" ja "hakuehdot" sijaan voidaan käyttää termejä "suodata"
          ja "suodatukset".
        </ListItem>
        <ListItem>
          lomakkeella ei näytetä erillistä haun suorittavaa painiketta, vaan
          jokainen hakuehdon valinta päivittää listaa automaattisesti
        </ListItem>
        <ListItem>
          hakutuloksissa (5) näytetään käyttäjän saapuessa näkymään kaikki
          tietokannasta löytyvät tulokset. Hakuehdoilla valitsemalla käyttäjä
          raja valmiiksi listattuja tuloksia.
        </ListItem>
        <ListItem>
          hakutulosten (5) paikalla näytetään{' '}
          <Code>
            <Link page="EmptyState" />
          </Code>{' '}
          vain, jos valituilla hakuehdoilla ei löydy tuloksia.
        </ListItem>
      </List>
      <SectionHeading>
        Jos hakuehtoja ei voida päivittää automaattisesti
      </SectionHeading>
      <Paragraph noMargin>
        <Strong>
          Jos hakuehtoja ei voida teknisistä syistä päivittää hakuun
          automaattisesti, pyritään hakulomake ensisijaisesti eriyttämään
          modaaliin.
        </Strong>{' '}
        (Esim. Omat sivut / Suositellut työpaikat, jossa suoritettava
        tietokantahaku on erityisen raskas, eikä tuloksia kannata päivittää
        jokaisella hakuehtojen muutoksella. Huom. kyseinen esimerkkitapaus on
        samalla myös hakulomake ilman hakusana-kenttää)
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Hakumodaali avataan painikkeella, jonka label on "Hae X". Painike
          sijoitetaan näkymään samaan kohtaan kuin hakulomakekin
          sijoitettaisiin.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Jos modaaliin avautuvalla lomakkeella ei ole hakukenttää (1), voi
              painikkeen label olla esim "Suodata X". Tällöin painikkeen ikoni
              on <Code>SearchIcon</Code> sijaan <Code>FilterIcon</Code>.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          Modaalissa valitut hakuehdot listataan lomakekenttien jälkeen
          "Valitsemasi hakuehdot" -alueella{' '}
          <Code>
            <Link page="Tag" />
          </Code>
          -komponenteilla samaan tapaan kuin näkymässä näytettävän hakulomakeen
          "Nykyinen hakusi"-alueella (4).
        </ListItem>
        <ListItem>
          Valitut hakuehdot vaikuttavat hakutuloksiin (5) vasta kun käyttäjä on
          hyväksynyt valinnat modaalin sulkevalla "Hae X"-painikkeella.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Hae-painikkeen lisäksi modaalissa on myös Peruuta-painike, joka
              sulkee modaalin suorittamatta hakua.
            </ListItem>
            <ListItem>
              Modaalissa hakukentällä ei ole omaa Hae-painiketta
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          Kun käyttäjä on sulkenut modaalin, valitut hakuehdot listataan
          "Nykyinen hakusi" -alueella tagien sijaan{' '}
          <Code>
            <Link page="Badge" />
          </Code>
          -komponenteilla.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Käyttäjä voi muokata ja poistaa valitsemiaan yksittäisiä
              hakuehtoja avaamalla modaalin ja suorittamalla haun uudelleen.
            </ListItem>
            <ListItem>
              Kaikki valitut hakuehdot voidaan tyhjentää kerralla avaamatta
              modaalia hakuehdot tyhjentävällä "Poista kaikki hakuehdot"
              -painikkeella.
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Playground
        enableOverflow
        example={PatternExampleSearchInModal}
      />
    </Section>
    <Section title="Sijoittuminen näkymässä">
      <SectionHeading>Haku omana näkymänään</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Hakulomake ja sen tuottamat hakutulokset voivat muodostaa oman
          näkymänsä
        </ListItem>
        <ListItem>
          Näkymän pääotsikossa (semanttisesti <Code>h1</Code>) tulee selvitä
          haettavan tiedon tyyppi (otsikkona esim. Palvelut, Avoimet työpaikat)
        </ListItem>
      </List>
      <Spacer marginBottom="layout-sm">
        <ZoomedExample>
          <PatternExampleHakulomake3 />
        </ZoomedExample>
      </Spacer>
      <SectionHeading>Haku osana näkymää</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Hakulomake ja sen tuottamat hakutulokset voidaan upottaa myös osaksi
          muita katselunäkymiä (kuten aluesivut)
        </ListItem>
        <ListItem>
          Tällöin hakualueelle annetaan näkymään sopiva otsikkotaso, josta
          selviää haettavan tiedon tyyppi (otsikkona esim. Alueen avoimet
          työpaikat)
        </ListItem>
      </List>
      <ZoomedExample>
        <PatternExampleHakulomake4 />
      </ZoomedExample>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <SectionHeading>Työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>Avoimet työpaikat -hakusivu</ListItem>
        <ListItem>Palvelut-hakusivu</ListItem>
        <ListItem>Palveluhaku osana Henkilöasiakkaat-sivua</ListItem>
        <ListItem>Työnantaja-asiakkaan työntekijähaku</ListItem>
      </List>
      <SectionHeading>Asiantuntijan työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>Palvelut-hakusivu</ListItem>
      </List>
    </Section>
  </Content>
)
export default Page
