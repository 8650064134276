import React, { ComponentProps, useState } from 'react'

import {
  Button,
  ButtonGroup,
  Buttons,
  CardViewFillIcon,
  Checkbox,
  CheckboxGroup,
  Col,
  Collapsible,
  Columns,
  Divider,
  FilterIcon,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  InlineLabel,
  InlineList,
  InlineListItem,
  Input,
  ListViewFillIcon,
  Main,
  PlusIcon,
  Radio,
  RadioGroup,
  Row,
  SearchIcon,
  Select,
  Spacer,
  SquareButton,
  Stack,
  Tag,
  TagButton,
  TagDivider,
  Tags,
  useBreakpoint,
  XIcon
} from '@te-digi/styleguide'

const PatternExampleHakulomakeActive = () => {
  const [isOpen, setIsOpen] = useState(true)
  const [value1, setValue1] =
    useState<ComponentProps<typeof RadioGroup>['value']>('')
  const [value2, setValue2] =
    useState<ComponentProps<typeof RadioGroup>['value']>('')
  const [checkboxGroupValue, setCheckboxGroupValue] = useState<
    ComponentProps<typeof CheckboxGroup>['value']
  >(['1'])
  const [selected, setSelected] = useState('card') // ButtonGroup
  const isMd = useBreakpoint('md')

  return (
    <Columns layout="wide">
      <Main>
        <FormLayout>
          <FormLayoutItem>
            <FormGroup>
              <Row
                alignItems="end"
                justifyContent="between"
                gap="md"
              >
                <Col
                  md={{ alignSelf: 'stretch' }}
                  xs={12}
                >
                  <Input
                    clearable
                    label="Hakusana"
                    subLabel="Hae <tietoja> esimerkiksi <hakusanatyypillä> tai <hakusanatyypillä>"
                  />
                </Col>
                <Col xs="auto">
                  <Spacer marginTop="sm">
                    <Button iconLeft={<SearchIcon />}>Hae {'<tietoja>'}</Button>
                  </Spacer>
                </Col>
              </Row>
            </FormGroup>
            <Row>
              <Col
                md={6}
                xs={12}
              >
                <FormGroup>
                  <Select
                    label="Tärkeä hakuehto"
                    placeholder="Kirjoita tai valitse..."
                  />
                </FormGroup>
              </Col>
              <Col
                md={6}
                xs={12}
              >
                <FormGroup>
                  <Select
                    label="Tärkeä hakuehto"
                    placeholder="Kirjoita tai valitse..."
                  />
                </FormGroup>
              </Col>
            </Row>
            <Divider />
            <Collapsible
              isOpen={isOpen}
              onToggle={() => setIsOpen(!isOpen)}
              trigger={
                <Button
                  iconLeft={<FilterIcon />}
                  variant="plain"
                  data-test-id="advanced-filters-toggle"
                >
                  {isOpen ? 'Piilota' : 'Näytä'} tarkemmat hakuehdot
                </Button>
              }
            >
              <Row>
                <Col
                  md={6}
                  xs={12}
                >
                  <FormGroup>
                    <Stack gap="xs">
                      <Select
                        label="Tarkempi hakuehto 1"
                        placeholder="Kirjoita tai valitse..."
                      />
                      <Tag
                        color="primary"
                        label="Valittu hakuehto 1"
                        size="xs"
                      >
                        <TagDivider />
                        <TagButton icon={<XIcon />}>Poista</TagButton>
                      </Tag>
                    </Stack>
                  </FormGroup>
                </Col>
                <Col
                  md={6}
                  xs={12}
                >
                  <FormGroup>
                    <Select
                      label="Tarkempi hakuehto 2"
                      placeholder="Kirjoita tai valitse..."
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col
                  md={4}
                  xs={12}
                >
                  <FormGroup>
                    <Select
                      label="Tarkempi hakuehto 3"
                      placeholder="Kirjoita tai valitse..."
                    />
                  </FormGroup>
                </Col>
                <Col
                  md={4}
                  xs={12}
                >
                  <FormGroup>
                    <Select
                      label="Tarkempi hakuehto 4"
                      placeholder="Kirjoita tai valitse..."
                    />
                  </FormGroup>
                </Col>
                <Col
                  md={4}
                  xs={12}
                >
                  <FormGroup>
                    <Select
                      label="Tarkempi hakuehto 5"
                      placeholder="Kirjoita tai valitse..."
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buttons>
                    <Button
                      color="primary"
                      iconLeft={<PlusIcon />}
                      variant="outline"
                    >
                      Valitse tarkempi hakuehto 6
                    </Button>
                  </Buttons>
                </Col>
              </Row>
              <Row>
                <Col
                  md={4}
                  xs={12}
                >
                  <RadioGroup
                    label="Hakuehto 1"
                    onChange={e => setValue1(e)}
                    value={value1}
                    name="radio-group-1"
                  >
                    <Radio value="1">Vaihtoehto A</Radio>
                    <Radio value="2">Vaihtoehto B</Radio>
                    <Radio value="3">Vaihtoehto C</Radio>
                  </RadioGroup>
                </Col>
                <Col
                  md={4}
                  xs={12}
                >
                  <RadioGroup
                    label="Hakuehto 2"
                    onChange={e => setValue2(e)}
                    value={value2}
                    name="radio-group-2"
                  >
                    <Radio value="1">Vaihtoehto X</Radio>
                    <Radio value="2">Vaihtoehto Y</Radio>
                    <Radio value="3">Vaihtoehto Z</Radio>
                  </RadioGroup>
                </Col>
                <Col
                  md={4}
                  xs={12}
                >
                  <CheckboxGroup
                    label="Hakuehto 3"
                    onChange={e => setCheckboxGroupValue(e)}
                    value={checkboxGroupValue}
                  >
                    <Checkbox value="1">Vaihtoehto 1</Checkbox>
                    <Checkbox value="2">Vaihtoehto 2</Checkbox>
                    <Checkbox value="3">Vaihtoehto 3</Checkbox>
                  </CheckboxGroup>
                </Col>
              </Row>
            </Collapsible>
          </FormLayoutItem>
        </FormLayout>
        <Spacer marginBottom="sm">
          <Heading
            level={2}
            noMargin
            size={5}
          >
            Nykyinen hakusi
          </Heading>
        </Spacer>
        <Row
          alignItems="center"
          gap="md"
          justifyContent="start"
        >
          <Col xs="auto">
            <Tags noMargin>
              <Tag
                label="hakusana"
                size="xs"
              >
                <TagDivider />
                <TagButton icon={<XIcon />}>Poista</TagButton>
              </Tag>
              <Tag
                color="primary"
                label="Valittu hakuehto 1"
                size="xs"
              >
                <TagDivider />
                <TagButton icon={<XIcon />}>Poista</TagButton>
              </Tag>
              <Tag
                color="primary"
                label="Vaihtoehto 1"
                size="xs"
              >
                <TagDivider />
                <TagButton icon={<XIcon />}>Poista</TagButton>
              </Tag>
            </Tags>
          </Col>
          <Col xs="auto">
            <Button
              iconRight={<XIcon />}
              variant="plain"
            >
              Tyhjennä haku
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row alignItems="center">
          <Col>
            <Spacer marginBottom="sm">
              <Heading
                level={2}
                noMargin
                size={5}
                aria-live="polite"
              >
                <InlineList>
                  <InlineListItem>Löytyi N osumaa</InlineListItem>
                  <InlineListItem>Sivu 1/N</InlineListItem>
                </InlineList>
              </Heading>
            </Spacer>
          </Col>
          <Col
            md={4}
            sm="auto"
            xs={12}
          >
            <Spacer marginBottom="sm">
              <InlineLabel
                htmlFor="result-example-order"
                label="Järjestys"
              >
                <Select
                  id="result-example-order"
                  placeholder="Osuvin"
                />
              </InlineLabel>
            </Spacer>
          </Col>

          {isMd && (
            <Col
              sm="auto"
              xs={12}
            >
              <Spacer marginBottom="sm">
                <InlineLabel
                  htmlFor="result-example-order"
                  label="Näytä"
                >
                  <ButtonGroup>
                    <SquareButton
                      ariaPressed={selected === 'card' ? 'true' : 'false'}
                      iconLeft={<CardViewFillIcon />}
                      onClick={() => setSelected('card')}
                      variant={selected === 'card' ? undefined : 'outline'}
                    >
                      Kortit
                    </SquareButton>
                    <SquareButton
                      ariaPressed={selected === 'list' ? 'true' : 'false'}
                      iconLeft={<ListViewFillIcon />}
                      onClick={() => setSelected('list')}
                      variant={selected === 'list' ? undefined : 'outline'}
                    >
                      Lista
                    </SquareButton>
                  </ButtonGroup>
                </InlineLabel>
              </Spacer>
            </Col>
          )}
        </Row>
      </Main>
    </Columns>
  )
}

export { PatternExampleHakulomakeActive }
