import React, { ComponentProps, useState } from 'react'

import {
  Badge,
  Badges,
  Button,
  Col,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Inline,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Paragraph,
  Row,
  SearchIcon,
  Select,
  Tag,
  TagButton,
  TagDivider,
  Tags,
  XIcon
} from '@te-digi/styleguide'

type Value = ComponentProps<typeof Select>['value']

const SELECT1_OPTIONS = [
  { label: 'Helsinki', value: 'helsinki-1' },
  { label: 'Espoo', value: 'espoo-2' },
  { label: 'Tampere', value: 'tampere-3' },
  { label: 'Vantaa', value: 'vantaa-4' },
  { label: 'Oulu', value: 'oulu-5' },
  { label: 'Turku', value: 'turku-6' },
  { label: 'Jyväskylä', value: 'jyvaskyla-7' },
  { label: 'Lahti', value: 'lahti-8' },
  { label: 'Kuopio', value: 'kuopio-9' },
  { label: 'Pori', value: 'pori-10' }
]

const SELECT2_OPTIONS = [
  { label: 'Suomi', value: 'suomi-1' },
  { label: 'Ruotsi', value: 'ruotsi-2' },
  { label: 'Englanti', value: 'englanti-3' }
]

const PatternExampleSearchInModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [customOption, setCustomOption] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<Value[]>([])
  const [committedOptions, setCommittedOptions] = useState<Value[]>([])

  const onModalOpenClick = () => {
    setIsModalOpen(isModalOpen => !isModalOpen)
    setSelectedOptions(committedOptions)
  }

  const onModalClose = () => setIsModalOpen(false)

  const commitSelection = () => {
    setCommittedOptions(selectedOptions)
    onModalClose()
  }

  const addToSelection = (value: Value) => {
    setSelectedOptions(selectedOptions.concat([value]))
  }

  const removeFromSelection = (selected: Value) => {
    setSelectedOptions(
      selectedOptions.filter(option => option?.value !== selected?.value)
    )
  }

  const emptyCommittedSelection = () => {
    setCommittedOptions([])
    setCustomOption('')
  }

  return (
    <>
      <FormLayout>
        <FormGroup noMargin>
          <Button
            iconLeft={<SearchIcon />}
            onClick={onModalOpenClick}
          >
            Hae {'<tietoja>'}
          </Button>
        </FormGroup>
        {(committedOptions.length > 0 || customOption) && (
          <FormGroup noMargin>
            <Label
              as={'p'}
              id={'committed-filters-label'}
            >
              Nykyinen hakusi
            </Label>
            <Inline gapX="md">
              <Badges noMargin>
                {customOption && (
                  <Badge
                    color="secondary"
                    key={customOption}
                  >
                    {customOption}
                  </Badge>
                )}
                {committedOptions.map(option => (
                  <Badge
                    color="primary"
                    key={option?.value}
                  >
                    {option?.label}
                  </Badge>
                ))}
              </Badges>
              <Button
                iconRight={<XIcon />}
                onClick={emptyCommittedSelection}
                variant="plain"
              >
                Tyhjennä haku
              </Button>
            </Inline>
          </FormGroup>
        )}
      </FormLayout>
      <Modal
        ariaLabelledby="modal-heading"
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        size="xl"
      >
        <ModalHeader onCloseButtonClick={onModalClose}>
          <ModalHeading id="modal-heading">Hae {'<tietoja>'}</ModalHeading>
        </ModalHeader>
        <ModalBody>
          <Paragraph>
            Hae {'<tietoja>'} hakusanalla tai valitsemalla valikoista
            hakuehtoja.
          </Paragraph>
          <FormLayout>
            <FormLayoutItem>
              <FormGroup>
                <Row
                  alignItems="end"
                  justifyContent="between"
                  gap="md"
                >
                  <Col>
                    <Input
                      clearable
                      label="Hakusana"
                      onChange={event => setCustomOption(event.target.value)}
                      subLabel="Hae <tietoja> esimerkiksi <hakusanatyypillä> tai <hakusanatyypillä>"
                      value={customOption}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <Row>
                <Col
                  lg={6}
                  xs={12}
                >
                  <FormGroup>
                    <Select
                      label="Kaupunki"
                      onChange={value => {
                        addToSelection(value)
                      }}
                      options={SELECT1_OPTIONS.map(option => {
                        console.log(selectedOptions, option)
                        return {
                          ...option,
                          disabled: !!selectedOptions.find(
                            selectedOption =>
                              selectedOption?.value === option.value
                          )
                        }
                      })}
                      value={null}
                    />
                  </FormGroup>
                </Col>
                <Col
                  lg={6}
                  xs={12}
                >
                  <FormGroup>
                    <Select
                      label="Kieli"
                      onChange={value => {
                        addToSelection(value)
                      }}
                      options={SELECT2_OPTIONS.map(option => {
                        console.log(selectedOptions, option)
                        return {
                          ...option,
                          disabled: !!selectedOptions.find(
                            selectedOption =>
                              selectedOption?.value === option.value
                          )
                        }
                      })}
                      value={null}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormLayoutItem>
            {(selectedOptions.length > 0 || customOption) && (
              <FormGroup noMargin>
                <Label
                  as={'p'}
                  id={'selected-filters-label'}
                >
                  Valitsemasi hakuehdot
                </Label>
                <Row>
                  <Col>
                    <Tags noMargin>
                      {customOption && (
                        <Tag
                          key={'selected-badge_' + customOption}
                          label={customOption}
                          size={'xs'}
                        >
                          <TagDivider />
                          <TagButton
                            icon={<XIcon />}
                            onClick={() => setCustomOption('')}
                          >
                            Poista
                          </TagButton>
                        </Tag>
                      )}
                      {selectedOptions.map(option => (
                        <Tag
                          color="primary"
                          key={'selected-badge_' + option?.value}
                          label={option?.label}
                          size={'xs'}
                        >
                          <TagDivider />
                          <TagButton
                            icon={<XIcon />}
                            onClick={() => removeFromSelection(option)}
                          >
                            Poista
                          </TagButton>
                        </Tag>
                      ))}
                    </Tags>
                  </Col>
                </Row>
              </FormGroup>
            )}
          </FormLayout>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onModalClose}
            variant="outline"
          >
            Peruuta
          </Button>
          <Button onClick={commitSelection}>Hae {'<tietoja>'}</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export { PatternExampleSearchInModal }
